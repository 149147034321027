/*
 *
 * EvaluationDetailView constants
 *
 */

export const DEFAULT_ACTION = 'app/EvaluationDetailView/DEFAULT_ACTION';
export const GLOBAL_ERROR = 'app/EvaluationDetailView/GLOBAL_ERROR';
export const GET_EVALUATED_CANDIDATE_DETAIL_DATA = 'app/EvaluationDetailView/GET_EVALUATED_CANDIDATE_DETAIL_DATA';
export const FETCH_EVALUATED_CANDIDATE_DETAIL_DATA_SUCCESS =
  'app/EvaluationDetailView/FETCH_EVALUATED_CANDIDATE_DETAIL_DATA_SUCCESS';
