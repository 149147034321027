/*
 *
 * EvaluationDetailView actions
 *
 */

import { GET_EVALUATED_CANDIDATE_DETAIL_DATA, FETCH_EVALUATED_CANDIDATE_DETAIL_DATA_SUCCESS } from './constants';

export function fetchEvaluatedCandidateDetailViewData(evaluator_candidate_id) {
  return {
    type: GET_EVALUATED_CANDIDATE_DETAIL_DATA,
    evaluator_candidate_id,
  };
}

export function fetchEvaluatedCandidateDetailViewSuccess(data) {
  return {
    type: FETCH_EVALUATED_CANDIDATE_DETAIL_DATA_SUCCESS,
    data,
  };
}
